import * as React from "react";
import moment from "moment";
import useTranslation from "../../hooks/useTranslation";

import './Date.scss';

const inputMainProps = ['required', 'name', 'value', 'placeholder', 'min', 'max'];

function Date(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === 'value') {
                value = moment(value).format('YYYY-MM-DD');
            } else if (propName === 'min' || propName === 'max') {
                value = moment(value).format('YYYY-MM-DD');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleChange = e => {
        props.onChange(e.target.name, e.target.value);
    }

    const disabled = props.disabled || false;
    let className = "FormInput FormDate";
    disabled && (className += " disabled");
    props.required && (className += " required");
    const id = `field_${props.name}`;
    return (
        <div className={className}>
            {props.label !== false && <label className="label" htmlFor={id}>{props.label || t(props.name)}</label>}
            <input type="date" {...getMainProps(inputMainProps)} onChange={handleChange} disabled={disabled}/>
        </div>
    );
}

export default Date;