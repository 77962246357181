import './Checkbox.scss';

const inputMainProps = ['required', 'name', 'value', 'placeholder'];

function Checkbox(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === "value") {
                result["checked"] = value;
            } else if (elementProps.indexOf(propName) !== -1) {
                result[propName] = value;
            }
        });
        return result;
    }

    const handleChange = e => {
        props.onChange(e.target.name, e.target.checked);
    }

    let style = props.style || {};
    let className = 'FormInput FormCheckbox';
    props.className && (className += `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");
    const id = `field_${props.name}`;
    return (
        <label className={className} style={style} htmlFor={id}>
            <input type="checkbox" {...getMainProps(inputMainProps)} onChange={handleChange} id={id}/>
            <label htmlFor={id} className="label">{props.label}</label>
        </label>
    );
}

export default Checkbox;