import React, {useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from "./core/Layout";
import Homepage from './core/Homepage';
import {useAppData} from "./context/AppDataContext";
import Loading from "./bricks/Loading";
import {useUserData} from "./context/UserContext";
import useTranslation from "./hooks/useTranslation";
import {ToastContainer} from "react-toastify";
import CourseSelection from "./core/CourseSelection";
import TestIntroduction from "./core/TestIntroduction";
import Test from "./core/test/Test";
import Profile from "./core/profile/Profile";
import CourseDraw from "./core/CourseDraw";
import CourseOverview from "./core/course-overview/CourseOverview";
import RegistrationIncompleteNotification from "./core/RegistrationIncompleteNotification";
import SetNewPassword from "./core/set-new-password/SetNewPassword";

import './App.scss';

const App = () => {
    const {translations, language} = useTranslation();
    const {isLoading, isRegistrationOpen, isCourseDraw, drawIsOver} = useAppData();
    const {
        user,
        hasSeenTestIntroduction,
        testIntroductionEndTime,
        recordTestIntroductionStart,
        testToComplete,
        testResultList,
        courseRegistration,
        otherTest,
        skipSortingTest
    } = useUserData();
    const [countDown, setCountDown] = useState(0);

    const getCorrectComponent = () => {
        if (courseRegistration) {
            if (otherTest) {
                const {testId, testToComplete} = otherTest;
                return <Test testType="otherTest" testId={testId} testIndex={testToComplete} disableSkipButtton/>;
            }

            // CourseOverview
            if (drawIsOver) {
                return <CourseOverview/>
            }

            // CourseDraw
            if (isCourseDraw) {
                return <CourseDraw/>
            }
        }

        // is registration open
        if (isRegistrationOpen) { // todo dořešit reload po countdownu

            if (skipSortingTest) {
                return <CourseSelection/>;
            }

            // check if user saw test introduction
            if (!hasSeenTestIntroduction) {
                recordTestIntroductionStart(); // record the start time of the TestIntroduction view
                return <TestIntroduction onCountDownComplete={() => setCountDown(countDown + 1)}/>;
            } else {
                // or if the user is still supposed to be looking at the test introduction
                if (testIntroductionEndTime > Date.now()) {
                    return <TestIntroduction onCountDownComplete={() => setCountDown(countDown + 1)}/>;
                }
            }

            // check if user has fulfilled test
            if (testToComplete) {
                return <Test testIndex={testToComplete.testToComplete}/>;
            }

            // check that user has filled test
            if (testResultList.length) {
                return <CourseSelection/>;
            }
        }

        return <RegistrationIncompleteNotification/>;

    }

    const renderContent = () => {
        if (isLoading) return <Loading/>;

        return (
            <Routes>
                {!user && <Route path="/reset-password/:recoveryString" element={<SetNewPassword/>}/>}
                {!user && <Route path="/" element={<Homepage/>}/>}
                {user && <Route path="/profile" element={<Profile/>}/>}
                {user && <Route path="/" element={getCorrectComponent()}/>}
            </Routes>
        );
    }

    return (
        <BrowserRouter>
            <Layout>
                <ToastContainer style={{width: "50%"}}/>
                {language && translations && renderContent()}
            </Layout>
        </BrowserRouter>
    );
}

export default App;
