import React from "react";
import 'bootstrap';
import useTranslation from "../hooks/useTranslation";
import {useUserData} from "../context/UserContext";
import {useAppData} from "../context/AppDataContext";

import "./AppInfo.scss";

const AppInfo = ({step}) => {
    const {t} = useTranslation();
    const {testResultList, user} = useUserData();
    const {data} = useAppData();

    const _getUserLevel = () => {
        const testResult = testResultList?.find(testResult => testResult.testType === "sortingTest" && testResult.app === process.env.REACT_APP_APP_TYPE);
        if (testResult && !testResult.testToComplete) {
            const userPoints = testResult.result;
            const levelPointsConfig = data.period.levelPointsConfig.find(lvlGroup => user.typeList.some(usrType => lvlGroup.acceptableTypes.indexOf(usrType) !== -1));
            const userLevel = levelPointsConfig.config.find(levelCfg => levelCfg.min <= userPoints && levelCfg.max >= userPoints)?.level;
            return (
                <p>{t("yourLevel")}: {userLevel}</p>
            );
        }
    }

    if (!step) {
        return null;
    }
    const period = data.period;
    let randomSelectionUsersForCourses = t("randomSelectionUsersForCourses");
    if (period.courseDrawDate && period.courseDrawDaysLimit) {
        const courseDrawDate = new Date(period.courseDrawDate);
        const courseDrawEnd = new Date(courseDrawDate.getTime() + period.courseDrawDaysLimit * 60 * 60 * 24 * 1000);
        randomSelectionUsersForCourses = randomSelectionUsersForCourses.replace("%drawCourseEndDate%", `${courseDrawEnd.toLocaleDateString()} ${courseDrawEnd.toLocaleTimeString()}`);
    }

    return (
        <div className={`alert alert-info AppInfo`} role="alert">
            <h3>{t(`appStep${step}`)}</h3>
            {step === 4 && <p>{randomSelectionUsersForCourses}</p>}
            <p>{t("totalRegisteredInSystem")}: {data.userCount}</p>
            {_getUserLevel()}
        </div>
    );
};

export default AppInfo;
