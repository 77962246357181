import React, {useEffect, useState} from "react";
import {Carousel} from 'react-responsive-carousel';
import AppInfo from "../../bricks/AppInfo";
import {axiosInstance} from "../../axios";
import useTranslation from "../../hooks/useTranslation";
import Loading from "../../bricks/Loading";
import ErrorMessage from "../../bricks/ErrorMessage";
import Button from "../../bricks/form/Button";
import TestQuestion from "./TestQuestion";
import TestOpenQuestion from "./TestOpenQuestion";
import TestAudioQuestion from "./TestAudioQuestion";
import {useUserData} from "../../context/UserContext";

import "./Test.scss";

const Test = ({testIndex, testType = "sortingTest", testId = null, disableSkipButtton = false}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [testQuestionGroups, setTestQuestionGroups] = useState();
    const [error, setError] = useState();
    const [answerMap, setAnswerMap] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const {addTestResult, endOtherTest} = useUserData();

    useEffect(() => {
        const init = async () => {
            try {
                const {data} = await axiosInstance.get(`/api/test/${testType}/${testIndex}${testId ? `/${testId}` : ''}`);
                setTestQuestionGroups(groupQuestions(data));
            } catch (e) {
                console.log(e);
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        testIndex && init();
    }, [testIndex]);

    const groupQuestions = (questionList) => {
        const tmp = {};
        questionList.forEach(question => {
            if (question.group) {
                if (tmp[question.group]) {
                    tmp[question.group].push(question);
                } else {
                    tmp[question.group] = [question];
                }
            } else {
                tmp[question._id] = [question];
            }
        });
        return tmp;
    }

    const handleChange = questionsPerView => {
        return (name, value) => {
            if (questionsPerView === 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
            setAnswerMap(answerMap => {
                return {...answerMap, [name]: value};
            });
        }
    }

    const onChangeOpenAnswer = (name, value) => {
        setAnswerMap(answerMap => {
            return {...answerMap, [name]: value};
        });
    }

    const _handleSubmit = async (skipTest = false) => {
        setIsLoading(true);
        if (skipTest) {
            if (!window.confirm('skipTestAlert')) {
                return;
            }
        }
        try {
            const body = {
                testIndex,
                answerMap,
                testType,
                ...testId && {testId}
            };
            const {data} = await axiosInstance.post("/api/test/evaluate", body);
            setCurrentQuestionIndex(0);
            if (data.testType === "otherTest" && !data.testToComplete) {
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            }
            addTestResult(data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
            endOtherTest();
        }
    }

    const renderQuestions = () => {
        const items = [];
        let i = 1;
        Object.values(testQuestionGroups).forEach((testQuestionGroup) => {
            const questionList = [];
            testQuestionGroup.forEach(testQuestion => {
                const commonProps = {
                    question: `${i}. ${testQuestion.question}`,
                    onChange: handleChange(testQuestionGroup.length),
                    className: "question-wrapper",
                    value: answerMap[testQuestion._id],
                    testQuestion
                };
                i++;

                if (testQuestion.type === 0) {
                    questionList.push(<TestQuestion {...commonProps} />);
                } else if (testQuestion.type === 1) {
                    questionList.push(<TestOpenQuestion {...commonProps} onChange={onChangeOpenAnswer}/>);
                } else if (testQuestion.type === 2) {
                    questionList.push(<TestAudioQuestion {...commonProps} record={testQuestion.record}/>);
                }
            });
            items.push(<>{React.Children.toArray(questionList)}</>);
        });

        return React.Children.toArray(items);
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (error) {
            return <ErrorMessage error={error}/>;
        } else {
            const fillTest = testIndex === "2" ? ` ${t("fillTest")}` : "";
            const firstTest = parseInt(testIndex) === 1;
            const lastQuestion = currentQuestionIndex + 1 === Object.keys(testQuestionGroups).length;

            const skipButton = !disableSkipButtton && firstTest && (
                <div className="test-controls">
                    <div>
                        <label>{t("skipTestLabel")}</label>
                        <Button type="button" label={t("skipTestButton")} onClick={() => _handleSubmit(true)}/>
                    </div>
                </div>
            );

            return <>
                <div className={"container"}>
                    <label>{`${t("test")}: ${testIndex}${fillTest}`}</label>
                    <Carousel
                        showThumbs={false}
                        selectedItem={currentQuestionIndex}
                        onChange={setCurrentQuestionIndex}
                    >
                        {renderQuestions()}
                    </Carousel>
                    <div className="carousel-controls">
                        <Button type="button" label={t('prev')} disabled={!currentQuestionIndex}
                                onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}/>
                        <Button type="button" label={t('next')}
                                disabled={currentQuestionIndex + 1 === testQuestionGroups.length}
                                onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}/>
                    </div>
                    {skipButton}
                    {lastQuestion &&
                    <Button className="submit" type="button" label={t("send")} onClick={() => _handleSubmit()}/>}
                </div>
            </>
        }
    }

    return (
        <div className="Test" dir="ltr">
            <AppInfo step={testType === "sortingTest" ? 2 : 6}/>
            {renderContent()}
        </div>
    );
};

export default Test;
