const Config = {
    validationMap: {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        textRegex: /^[\sA-Za-zěščřžýáíéůúĚŠČŘŽÝÁÍÉÚŮďťĎŤ]*$/,
        period: /^\d{4}\/(0?[1-9]|1[012])$/,
        mobile: /^(\+?420\s?)?(601|602|603|604|605|606|607|702|701|704|720|721|722|723|724|725|726|727|728|729|730|731|732|733|734|735|736|737|738|739|608|770|771|772|773|774|775|776|777|778|790|799|792|705|703|797|767|779|791)\s?\d{3}\s?\d{3}$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ISODate: /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
        time: /^(\d{2}):(\d{2})/,
        date: /^(\d{4})-(\d{2})-(\d{2})$/
    },
    courseTypes: {
        0: "slavicSpeaking",
        1: "nonSlavicSpeaking",
        2: "spanishSpeaking",
        3: "vietnameseSpeaking",
        4: "chineseSpeaking",
        5: "arabicSpeaking",
        6: "euGroup",
        7: "exceptEU",
        8: "nonSlavicAndEU"
    },
    errorCodeMap: {
        missingRequiredKeys: 'missingRequiredKeys',
        dupliciteEmail: 'dupliciteEmail',
        testAlreadyCompleted: 'testAlreadyCompleted',
        permissionDenied: 'permissionDenied',
        mongoError: 'mongoError',
        noTestsFinished: 'noTestsFinished',
        showCourseIsNotAvailable: 'showCourseIsNotAvailable'
    },
    userAttributes: {
        name: {
            type: "text",
            infoMessage: "exactNameSurname"
        },
        surname: {
            type: "text",
            infoMessage: "exactNameSurname"
        },
        sex: {
            type: "select"
        },
        nationality: {
            type: "select"
        },
        residenceStatus: {
            type: "select"
        },
        mobile: {
            type: "text",
            infoMessage: "onlyCzechPhoneNumbers",
            prefix: "+420"
        },
        email: {
            type: "email"
        },
        password: {
            type: "password"
        },
        passwordAgain: {
            type: "password"
        },
        dateOfBirth: {
            type: "date"
        }
    },
    userLoginAttributes: {
        email: {
            type: "email"
        },
        password: {
            type: "password"
        }
    },
    appConfigAttributes: {
        actualPeriod: {
            type: "select",
            label: "Aktuální perioda"
        },
        bankAccount: {
            type: "text",
            label: "Číslo účtu"
        },
        coursePrice: {
            type: "number",
            label: "Záloha na kurz"
        },
        smsConfigAttributes: {
            smsAPIClientId: {
                type: "text",
                label: "SMS API Client ID"
            },
            smsAPIClientSecret: {
                type: "text",
                label: "SMS API Client Secret"
            },
            smsPrice: {
                type: "number",
                label: "Cena SMS bez DPH",
                mainAttrs: {
                    step: "any"
                }
            },
            smsBeforeCourseStart: {
                type: "checkbox",
                label: "SMS před začátkem kurzu"
            },
            smsAfterCourseRegistration: {
                type: "checkbox",
                label: "SMS po registraci do kurzu"
            },
            sendSmsBeforeCourseDelay: {
                type: "number",
                label: "Odeslat SMS v předstihu (h)",
            }
        },
        emailConfigAttributes: {
            emailSender: {
                type: "text",
                label: "Odesílatel e-mailu"
            },
            emailAfterRegistration: {
                type: "checkbox",
                label: "E-mail po registraci do systému"
            },
            emailAfterCourseRegistration: {
                type: "checkbox",
                label: "E-mail po registraci do kurzu"
            },
            emailAfterCourseRegistrationFullCourse: {
                type: "checkbox",
                label: "E-mail po registraci do kurzu jako náhradník"
            },
            emailAfterCourseChange: {
                type: "checkbox",
                label: "E-mail po změně kurzu"
            },
            emailAfterPaymentCredited: {
                type: "checkbox",
                label: "E-mail po přijetí platby"
            },
            emailAfterPaymentRefundStateChange: {
                type: "checkbox",
                label: "E-mail po změně stavu platby"
            },
            emailAfterIsAlternateStateChange: {
                type: "checkbox",
                label: "E-mail při přechodu z/do náhradníka"
            }
        }
    },
    levelPointsConfig: {
        level: {
            type: "text",
            label: "Úroveň"
        },
        officialLevel: {
            type: "text",
            label: "Oficiální úroveň"
        },
        min: {
            type: "number",
            label: "Min",
            mainAttrs: {
                step: "any"
            }
        },
        max: {
            type: "number",
            label: "Max",
            mainAttrs: {
                step: "any"
            }
        },
        order: {
            type: "hidden",
        }
    },
    testQuestionAttributes: {
        type: {
            label: "Typ otáky",
            type: "select",
            options: [{0: "Testová otázka"}, {1: "Otázka s otevřenou odpovědí"}]
        },
        app: {
            label: "Aplikace",
            type: "select",
            options: [{'main': "Hlavní aplikace"}, {'special': "Special aplikace"}]
        },
        test: {
            label: "Test",
            type: "select"
        },
        period: {
            label: "Perioda",
            type: "select"
        },
        question: {
            label: "Otázka",
            type: "text"
        },
        testType: {
            label: "Typ testu",
            type: "select",
            options: [{comparativeTest: 'Srovnávací test'}, {sortingTest: 'Rozřazovací test'}]
        }
    },
    days: {
        0: "monday",
        1: "tuesday",
        2: "wednesday",
        3: "thursday",
        4: "friday",
        5: "saturday",
        6: "sunday"
    },
    courseDateAttributes: {
        day: {
            type: "select",
            getLsiKeyByValue: (val) => Config.days[val],
            options: (getLsiValue) => {
                return Object.entries(window.ICPraha.courseLevel).map(([key, val]) => ({[key]: getLsiValue(val)}))
            }
        },
        start: {
            type: "datetime"
        },
        end: {
            type: "datetime"
        }
    },
    courseAttributes: {
        code: {
            type: "text",
            show: true,
            required: true
        },
        level: {
            type: "select",
            required: true
        },
        capacity: {
            type: "number",
            show: false,
            required: true
        },
        place: {
            type: "text",
            label: "coursePlace",
            show: true,
            required: true
        },
        floorAndRoom: {
            type: "text",
            show: true
        },
        startDate: {
            type: "date",
            label: "courseStartDate",
            show: true,
            required: true
        },
        endDate: {
            type: "date",
            label: "courseEndDate",
            show: true,
            required: true
        },
        lector: {
            type: "text",
            show: false,
            required: true
        },
        note: {
            type: "text",
            show: true
        },
        type: {
            type: "checkboxes",
            parseInt: true,
            show: false,
            label: "courseType",
            options: {
                0: 'slavic',
                1: 'nonSlavic',
                2: 'spanish',
                3: 'vietnamese',
                4: 'chinese',
                5: 'arabic',
                6: 'eu'
            }
        },
        period: {
            type: "select",
            show: false
        }
    },
    periodAttributes: {
        period: {
            type: "text",
            label: "Perioda"
        },
        registrationStartDate: {
            label: "Začátek registrace do systému",
            type: "datetime"
        },
        registrationEndDate: {
            label: "Konec registrace do systému",
            type: "datetime"
        },
        sortingTestStartDate: {
            label: "Začátek zobrazení testu (pro již registrované uživatele)",
            type: "datetime"
        },
        courseDrawDate: {
            label: "Datum začátku slosování kurzů",
            type: "datetime"
        },
        showDrawResultDate: {
            label: "Zobrazení výsledky losování všem uživatelům",
            type: "datetime"
        },
        depositDueDate: {
            type: "datetime",
            label: "Datum splatnosti zálohy na kurz"
        },
        userCourseRegistrationLimit: {
            type: "number",
            label: "Maximální počet uživatele registrací do kurzu"
        },
        pdfRegistrationNumber: {
            type: "string",
            label: "Registrační číslo pro PDF certifikát"
        },
        paymentCronStartDate: {
            type: "date",
            label: "Datum začátku automatického načítání plateb"
        },
        paymentCronEndDate: {
            type: "date",
            label: "Datum konce automatického načítání plateb"
        }
    },
    docxMimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    browserLanguageMap: {
        "af-ZA": "us",
        "am-ET": "us",
        "ar-AE": "ae",
        "ar-BH": "ae",
        "ar-DZ": "ae",
        "ar-EG": "ae",
        "ar-IQ": "ae",
        "ar-JO": "ae",
        "ar-KW": "ae",
        "ar-LB": "ae",
        "ar-LY": "ae",
        "ar-MA": "ae",
        "arn-CL": "us",
        "ar-OM": "us",
        "ar-QA": "us",
        "ar-SA": "us",
        "ar-SY": "us",
        "ar-TN": "us",
        "ar-YE": "us",
        "as-IN": "us",
        "az-Cyrl-AZ": "us",
        "az-Latn-AZ": "us",
        "ba-RU": "us",
        "be-BY": "us",
        "bg-BG": "us",
        "bn-BD": "us",
        "bn-IN": "us",
        "bo-CN": "us",
        "br-FR": "us",
        "bs-Cyrl-BA": "us",
        "bs-Latn-BA": "us",
        "ca-ES": "us",
        "co-FR": "us",
        "cs-CZ": "cz",
        "cy-GB": "us",
        "da-DK": "us",
        "de-AT": "us",
        "de-CH": "us",
        "de-DE": "us",
        "de-LI": "us",
        "de-LU": "us",
        "dsb-DE": "us",
        "dv-MV": "us",
        "el-GR": "us",
        "en-029": "us",
        "en-AU": "us",
        "en-BZ": "us",
        "en-CA": "us",
        "en-GB": "us",
        "en-IE": "us",
        "en-IN": "us",
        "en-JM": "us",
        "en-MY": "us",
        "en-NZ": "us",
        "en-PH": "us",
        "en-SG": "us",
        "en-TT": "us",
        "en-US": "us",
        "en-ZA": "us",
        "en-ZW": "us",
        "es-AR": "us",
        "es-BO": "us",
        "es-CL": "us",
        "es-CO": "us",
        "es-CR": "us",
        "es-DO": "us",
        "es-EC": "us",
        "es-ES": "us",
        "es-GT": "us",
        "es-HN": "us",
        "es-MX": "us",
        "es-NI": "us",
        "es-PA": "us",
        "es-PE": "us",
        "es-PR": "us",
        "es-PY": "us",
        "es-SV": "us",
        "es-US": "us",
        "es-UY": "us",
        "es-VE": "us",
        "et-EE": "us",
        "eu-ES": "us",
        "fa-IR": "us",
        "fi-FI": "us",
        "fil-PH": "us",
        "fo-FO": "us",
        "fr-BE": "us",
        "fr-CA": "us",
        "fr-CH": "us",
        "fr-FR": "us",
        "fr-LU": "us",
        "fr-MC": "us",
        "fy-NL": "us",
        "ga-IE": "us",
        "gd-GB": "us",
        "gl-ES": "us",
        "gsw-FR": "us",
        "gu-IN": "us",
        "ha-Latn-NG": "us",
        "he-IL": "us",
        "hi-IN": "us",
        "hr-BA": "us",
        "hr-HR": "us",
        "hsb-DE": "us",
        "hu-HU": "us",
        "hy-AM": "us",
        "id-ID": "us",
        "ig-NG": "us",
        "ii-CN": "us",
        "is-IS": "us",
        "it-CH": "us",
        "it-IT": "us",
        "iu-Cans-CA": "us",
        "iu-Latn-CA": "us",
        "ja-JP": "us",
        "ka-GE": "us",
        "kk-KZ": "us",
        "kl-GL": "us",
        "km-KH": "us",
        "kn-IN": "us",
        "kok-IN": "us",
        "ko-KR": "us",
        "ky-KG": "us",
        "lb-LU": "us",
        "lo-LA": "us",
        "lt-LT": "us",
        "lv-LV": "us",
        "mi-NZ": "us",
        "mk-MK": "us",
        "ml-IN": "us",
        "mn-MN": "mn",
        "mn-Mong-CN": "mn",
        "moh-CA": "us",
        "mr-IN": "us",
        "ms-BN": "us",
        "ms-MY": "us",
        "mt-MT": "us",
        "nb-NO": "us",
        "ne-NP": "us",
        "nl-BE": "us",
        "nl-NL": "us",
        "nn-NO": "us",
        "nso-ZA": "us",
        "oc-FR": "us",
        "or-IN": "us",
        "pa-IN": "us",
        "pl-PL": "us",
        "prs-AF": "us",
        "ps-AF": "us",
        "pt-BR": "us",
        "pt-PT": "us",
        "qut-GT": "us",
        "quz-BO": "us",
        "quz-EC": "us",
        "quz-PE": "us",
        "rm-CH": "us",
        "ro-RO": "us",
        "ru-RU": "ru",
        "rw-RW": "us",
        "sah-RU": "ru",
        "sa-IN": "us",
        "se-FI": "us",
        "se-NO": "us",
        "se-SE": "us",
        "si-LK": "us",
        "sk-SK": "us",
        "sl-SI": "us",
        "sma-NO": "us",
        "sma-SE": "us",
        "smj-NO": "us",
        "smj-SE": "us",
        "smn-FI": "us",
        "sms-FI": "us",
        "sq-AL": "us",
        "sr-Cyrl-BA": "us",
        "sr-Cyrl-CS": "us",
        "sr-Cyrl-ME": "us",
        "sr-Cyrl-RS": "us",
        "sr-Latn-BA": "us",
        "sr-Latn-CS": "us",
        "sr-Latn-ME": "us",
        "sr-Latn-RS": "us",
        "sv-FI": "us",
        "sv-SE": "us",
        "sw-KE": "us",
        "syr-SY": "us",
        "ta-IN": "us",
        "te-IN": "us",
        "tg-Cyrl-TJ": "us",
        "th-TH": "us",
        "tk-TM": "us",
        "tn-ZA": "us",
        "tr-TR": "us",
        "tt-RU": "us",
        "tzm-Latn-DZ": "us",
        "ug-CN": "us",
        "uk-UA": "us",
        "ur-PK": "us",
        "uz-Cyrl-UZ": "us",
        "uz-Latn-UZ": "us",
        "vi-VN": "vn",
        "wo-SN": "us",
        "xh-ZA": "us",
        "yo-NG": "us",
        "zh-CN": "us",
        "zh-HK": "us",
        "zh-MO": "us",
        "zh-SG": "us",
        "zh-TW": "us",
        "zu-ZA": "us"
    }
};
const languageOptions = {
    cz: "Čeština", // cz
    us: "English", // en
    ru: "Русский", // ru
    ae: "العربية", // aj
    vn: "Tiếng Việt", // viet,
    cn: "Chinese",
    ua: "Ukraine"
};
export {languageOptions, Config};