import * as React from "react";
import useTranslation from "../../hooks/useTranslation";
import {useState} from "react";

import './Text.scss';

const inputMainProps = ['required', 'name', 'placeholder', 'type', 'onKeyPress'];

function Text(props) {
    const {t} = useTranslation();
    const [error, setError] = useState();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    const id = `field_${props.name}`;
    const colWidth = props.colWidth || 50;
    let className = `FormText FormInput col-${colWidth}`;
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");
    error && (className += " error");
    const type = props.password ? 'password' : 'text';

    const handleChange = e => {
        setError(null);
        const newValue = e.target.value;
        const regex = props.regex;
        if (regex && !regex.test(newValue)) {
            setError(props.validationError);
        }
        props.onChange(e.target.name, e.target.value);
    }

    return (
        <div className={className}>
            {props.label !== false && <label className="label" htmlFor={id}>{props.label || t(props.name)}</label>}
            <input type={type} id={id} {...getMainProps(inputMainProps)} disabled={disabled} onChange={handleChange}
                   value={props.value || ''}/>
            {error && <label className="error">{t(error)}</label>}
            {props.description && <label className="description">{props.description}</label>}
        </div>
    );
}

export default Text;