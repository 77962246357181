import React, {useState} from "react";
import "./TestOpenQuestion.scss";

const TestOpenQuestion = ({className, testQuestion, question, onChange}) => {
    const questionParts = question.split("%s");

    // Initialize answer state
    const [answer, setAnswer] = useState({});

    // Function to handle input changes
    const handleInputChange = e => {
        let index = e.target.name.split("-");
        index = index[1];
        setAnswer({...answer, [index]: e.target.value});
        onChange(testQuestion._id, {...answer, [index]: e.target.value});
        console.log({...answer, [index]: e.target.value});
    };

    // Render question parts and inputs
    const renderQuestionAndInputs = () => {
        let elements = [];
        questionParts.forEach((part, index) => {
            elements.push(<h3 key={`question-${testQuestion._id}-${index}`}>{part}</h3>);
            if (index !== questionParts.length - 1) {
                elements.push(
                    <>&nbsp;<input key={`input-${testQuestion._id}-${index}`}
                                   type="text"
                                   name={`${testQuestion._id}-${index}`}
                                   value={answer[index] || ''}
                                   onChange={handleInputChange}
                    /></>
                );
            }
        });
        return React.Children.toArray(elements);
    };

    return (
        <div className={`TestOpenQuestion ${className}`}>
            {testQuestion.instructions && <label className="instructions">{testQuestion.instructions}</label>}
            <div className="question">
                {renderQuestionAndInputs()}
            </div>
        </div>
    );
};

export default TestOpenQuestion;
