import React, {createContext, useContext, useEffect, useState} from "react";
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [testResultList, setTestResultList] = useState();
    const [courseRegistration, setCourseRegistration] = useState();
    const [error, setError] = useState();
    const [hasSeenTestIntroduction, setHasSeenTestIntroduction] = useState(false);
    const [testIntroductionEndTime, setTestIntroductionEndTime] = useState();
    const [testToComplete, setTestToComplete] = useState();
    const [otherTest, setOtherTest] = useState();
    const [skipSortingTest, setSkipSortingTest] = useState();

    const handleUserData = data => {
        setUser(data.user);
        setTestResultList(data.testResultList);
        setCourseRegistration(data.courseRegistration);
        setSkipSortingTest(data.isEligibleForSkipSortingTest);

        // Check if the user has already seen the test introduction
        if (data.user.testIntroductionStartTime) {
            setHasSeenTestIntroduction(true);

            // Determine when the user should finish looking at the test introduction
            const testDuration = parseInt(process.env.REACT_APP_TEST_INTRODUCTION_TIME);
            const testIntroductionEndTime = new Date(data.user.testIntroductionStartTime).getTime() + testDuration * 1000;
            setTestIntroductionEndTime(testIntroductionEndTime);
        }

        const sortingTest = data?.testResultList.find(({testType}) => testType === "sortingTest");
        if (!sortingTest || sortingTest.testToComplete) {
            setTestToComplete(sortingTest || {testToComplete: 1});
        }

        let testData = localStorage.getItem('otherTestData');
        if (testData) {
            setOtherTest(JSON.parse(testData));
        }
    }

    useEffect(() => {
        const checkTokenValidity = async () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                try {
                    const {data} = await axios.post('/api/user/loadAll', {}, {headers: {Authorization: `Bearer ${accessToken}`}});
                    setToken(accessToken);
                    handleUserData(data);
                } catch (e) {
                    setError(e);
                    console.log(e);
                    signOut();
                }
            }
        };

        checkTokenValidity();
    }, []);

    const signIn = async (email, password, stayLoggedIn = false) => {
        const {data} = await axios.post('/api/login', {email, password, stayLoggedIn});

        localStorage.setItem('accessToken', data.accessToken);
        setToken(data.accessToken);

        if (stayLoggedIn && data.refreshToken) {
            localStorage.setItem('refreshToken', data.refreshToken);
        }

        handleUserData(data);
    };

    const signUp = async (formData, stayLoggedIn = false) => {
        const {data} = await axios.post('/api/user/register', {
            ...formData,
            stayLoggedIn,
            sendResetPasswordEmail: false
        });

        localStorage.setItem('accessToken', data.accessToken);
        setToken(data.accessToken);

        handleUserData(data);
    };

    const signOut = (redirect) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('otherTestData');
        setUser(null);
        redirect?.();
    };

    const renewToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) return;

        const response = await axios.post('/api/refresh-token', {refreshToken});
        const {accessToken} = response.data;

        localStorage.setItem('accessToken', accessToken);
        setToken(accessToken);
        return accessToken;
    };

    const startOtherTest = (testId, testToComplete) => {
        const otherTestData = {testId, testToComplete};
        localStorage.setItem('otherTestData', JSON.stringify(otherTestData));
        setOtherTest(otherTestData);
    };

    const endOtherTest = () => {
        localStorage.removeItem('otherTestData');
        setOtherTest(null);
    };

    const recordTestIntroductionStart = async () => {
        const currentTime = new Date();
        try {
            await axios.post('/api/user/recordTestIntroductionStart', {startTime: currentTime}, {headers: {Authorization: `Bearer ${token}`}});
            // Update local state
            setUser(prevState => ({...prevState, testIntroductionStartTime: currentTime}));
            setHasSeenTestIntroduction(true);
            const testDuration = parseInt(process.env.REACT_APP_TEST_INTRODUCTION_TIME);
            const testIntroductionEndTime = new Date(currentTime).getTime() + testDuration * 1000;
            setTestIntroductionEndTime(testIntroductionEndTime);
        } catch (error) {
            setError(error);
            console.log(error);
        }
    };

    const addTestResult = testResult => {
        let newTestResultList = [...testResultList];
        if (newTestResultList && newTestResultList.length) {
            const index = newTestResultList.findIndex(existingTestResult => existingTestResult._id === testResult._id);
            if (index === -1) {
                newTestResultList.push(testResult);
            } else {
                newTestResultList[index] = testResult
            }
        } else {
            newTestResultList = [testResult];
        }
        setTestResultList(newTestResultList);

        if (testResult.testType === "sortingTest" && testResult.testToComplete) {
            setTestToComplete(testResult);
        } else {
            setTestToComplete(null);
        }
    }

    return (
        <UserContext.Provider
            value={{
                user,
                signIn,
                signUp,
                signOut,
                renewToken,
                token,
                error,
                testResultList,
                courseRegistration,
                setCourseRegistration,
                addTestResult,
                hasSeenTestIntroduction,
                testIntroductionEndTime,
                recordTestIntroductionStart,
                testToComplete,
                startOtherTest,
                otherTest,
                endOtherTest,
                skipSortingTest
            }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserData = () => {
    return useContext(UserContext);
};
