import './Button.scss';

function Button(props) {
    let className = "Button button main";

    // custom class
    if (props.className) {
        className += ` ${props.className}`;
    }

    // dark class
    if (props.dark) {
        className += ` secondary`;
    }

    // disabled class
    if (props.disabled) {
        className += ` disabled`;
    }

    const disabled = props.disabled || false;
    const type = props.type || 'button';

    let style = {};
    if (props.style) {
        style = props.style;
    }
    return (
        <button className={className} type={type} onClick={props.onClick} disabled={disabled} style={style}>
            {props.label}
        </button>
    );
}

export default Button;