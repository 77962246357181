import * as React from "react";
import ReactSelect from 'react-select';
import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";

import './Select.scss';
import {useState} from "react";

function Select(props) {
    const {t} = useTranslation();
    const [error, setError] = useState();

    let className = "FormSelect FormInput dropdown col-50";
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");
    const id = uniqueId();

    const handleChange = selected => {
        if (props.onValidate) {
            setError(props.onValidate(selected.value));
        }
        props.onChange(props.name, selected.value);
    }

    return (
        <div className={className}>
            {props.label !== false && <label className="label" htmlFor={id}>{props.label || t(props.name)}</label>}
            <ReactSelect className={className} placeholder={null} options={props.options} onChange={handleChange}/>
            {error && <label className="error">{t(error)}</label>}
        </div>
    );
}

export default Select;