import React from "react";
import AppInfo from "../bricks/AppInfo";
import CountDown from "./CountDown";
import useTranslation from "../hooks/useTranslation";
import {useUserData} from "../context/UserContext";

import './TestIntroduction.scss';

const TestIntroduction = ({onCountDownComplete}) => {
    const {t, translations, language} = useTranslation();
    const {testIntroductionEndTime} = useUserData();

    const renderTestInfoList = () => {
        const list = [];
        translations && language && Object.entries(translations).forEach(([key, val]) => {
            if (key.match(/test_info_(\d+)/)) {
                list.push(<li key={key} dangerouslySetInnerHTML={{__html: val}}/>);
            }
        });
        return (
            <ul>{list}</ul>
        );
    };

    return (
        <>
            <AppInfo step={2}/>
            <div className="TestIntroduction" dir="ltr">
                <div className="wrapper">
                    <h3>{t("test_info_header")}</h3>
                    {renderTestInfoList()}
                </div>
                <div className="countdown">
                    <CountDown end={testIntroductionEndTime} action={onCountDownComplete}/>
                </div>
            </div>
        </>
    );
};

export default TestIntroduction;
