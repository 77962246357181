import axios from 'axios';
import {useEffect} from "react";
import {useUserData} from "./context/UserContext";

const axiosInstance = axios.create();

const AxiosWrapper = () => {
    const {token, renewToken, signOut} = useUserData();

    useEffect(() => {
        if (!token) return;

        const requestInterceptor = axiosInstance.interceptors.request.use(
            config => {
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response.status === 401 && error.response.data.errorMessage === "JsonWebTokenError" && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const accessToken = await renewToken();
                    if (!accessToken) {
                        signOut();
                    } else {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                        return axiosInstance(originalRequest);
                    }
                }
                return Promise.reject(error);
            }
        );

        // Zrušení interceptorů při odmountování komponenty
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [token]);

    return null;
};

export {axiosInstance, AxiosWrapper};
