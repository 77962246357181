import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

const Button = ({
                    type = "button",
                    label = "",
                    onClick = () => {
                    },
                    colWidth = null,
                    inline = false,
                    disabled = false,
                    dark = false,
                    isLoading = false,
                    ...props
                }) => {

    const getLoadingBar = () => (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
    );

    const handleClick = () => {
        if (!disabled && !isLoading) {
            onClick();
        }
    };

    let className = `Button btn btn-primary wrapper`;
    dark && (className += " dark");
    colWidth && (className += ` ${colWidth}`);
    inline && (className += " inline");

    return (
        <button
            type={type}
            className={className}
            onClick={handleClick}
            disabled={disabled}
            {...props}
        >
            {isLoading && getLoadingBar()}
            {label}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(["button", "openModal", "submit", "closeModal", "plain"]),
    label: PropTypes.string,
    onClick: PropTypes.func,
    modalId: PropTypes.string,
    colWidth: PropTypes.string,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default Button;
