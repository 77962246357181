import React, {useEffect, useState} from "react";
import Loading from "../../bricks/Loading";
import {useUserData} from "../../context/UserContext";
import ErrorMessage from "../../bricks/ErrorMessage";
import {axiosInstance} from "../../axios";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import moment from "moment";
import useTranslation from "../../hooks/useTranslation";
import {Link} from "react-router-dom";
import Forms from "../../bricks/form/Forms";
import FileSaver from "file-saver";

import "./Profile.scss";

const Profile = () => {
    const {user} = useUserData();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [periodDataMap, setPeriodDataMap] = useState();
    const {t} = useTranslation();

    const getDataDividedByPeriod = data => {
        const tmp = {};

        data.testResultList.forEach(testResult => {
            const type = testResult.testType === "sortingTest" ? 'sortingTest' : 'outputTest';

            tmp[testResult.period] = {
                ...tmp[testResult.period],
                testResult: {
                    ...tmp[testResult.period]?.testResult,
                    [type]: testResult
                }
            };
        });

        data.courseRegistrationList.forEach(courseRegistration => {
            tmp[courseRegistration.period] = {
                ...tmp[courseRegistration.period],
                courseRegistration
            };
        });

        return tmp;
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const {data} = await axiosInstance.get(`/api/user/loadProfile`);
                setPeriodDataMap(getDataDividedByPeriod(data));
            } catch (error) {
                setError(error)
            } finally {
                setIsLoading(false);
            }
        };
        user && fetchUserData();
    }, []);

    const downloadCertificate = async (period) => {
        try {
            const {data} = await axiosInstance.get(`/api/certificate/generateUserCertificate/${period}`, {responseType: "blob"});
            FileSaver.saveAs(data, "certificate.pdf");
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const renderCourseRegistration = (courseRegistration) => {
        return courseRegistration ? (
            <div className="container">
                <h3 className="heading">{t('profile.courseRegistration')}</h3>
                <div className="row">
                    <label className="heading">{t('profile.course')}</label>
                    <label className="value">{courseRegistration.course.code}</label>
                </div>
                <div className="row">
                    <label className="heading">{t('profile.passed')}</label>
                    <label className="value">{courseRegistration.passed ? t('yes') : t('no')}</label>
                </div>
                <div className="row">
                    <label className="heading">{t('profile.courseStartDate')}</label>
                    <label className="value">{moment(courseRegistration.course.startDate).format('DD.MM.YYYY')}</label>
                </div>
                <div className="row">
                    <label className="heading">{t('profile.courseEndDate')}</label>
                    <label className="value">{moment(courseRegistration.course.endDate).format('DD.MM.YYYY')}</label>
                </div>
                <div className="row">
                    <label className="heading">{t('profile.courseLevel')}</label>
                    <label className="value">{courseRegistration.level}</label>
                </div>
                {courseRegistration.allowCertDownload &&
                <div className="row">
                    <label className="heading">{t('profile.downloadCertificate')}</label>
                    <label className="value"><Forms.Button
                        onClick={() => downloadCertificate(courseRegistration.period)}
                        label={t('downloadCertificate')}/></label>
                </div>}
            </div>
        ) : null;
    }

    const renderTestResult = (testResult) => {
        return testResult ? (
            <div className="container testResult">
                <h3 className="heading">{t('profile.testResults')}</h3>
                <div className="row">
                    <label>{t('profile.testType')}</label>
                    <label>{t('profile.points')}</label>
                    <label>{t('profile.createdAt')}</label>
                </div>
                {React.Children.toArray(Object.entries(testResult).map(([type, {result, createdAt}]) =>
                    <div className="row">
                        <label>{type}</label>
                        <label>{result}</label>
                        <label>{moment(createdAt).format('DD.MM.YYYY')}</label>
                    </div>
                ))}
            </div>
        ) : null;
    }

    const renderUserData = () => {
        return (
            <Accordion allowZeroExpanded>
                {React.Children.toArray(Object.entries(periodDataMap).map(([period, periodData]) => (
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>{period}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="panel-wrapper">
                                {renderCourseRegistration(periodData.courseRegistration)}
                                {renderTestResult(periodData.testResult)}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                )))}
            </Accordion>
        );
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (error) {
            return <ErrorMessage error={error}/>;
        } else {
            return renderUserData();
        }
    }

    return (
        <div className="Profile">
            <Link to="/" className="Button button main backToApp">{'<- ' + t('backToApp')}</Link>
            {renderContent()}
        </div>
    );
};

export default Profile;
