import * as React from 'react';
import "./Radio.scss";

function Radio({name, options, label, onChange, value = null}) {

    const handleChange = (e) => {
        onChange?.(e.target.name, e.target.value);
    }

    return (
        <label className="FormRadio">
            {label}
            <div className="options-container">
                {React.Children.toArray(options.map(option => (
                        <div className="option">
                            <label>
                                <input name={name} checked={value === option.value} type="radio" value={option.value}
                                       onChange={handleChange}/>
                                {option.label}
                            </label>
                        </div>
                    )
                ))}
            </div>
        </label>
    );
}

export default Radio;