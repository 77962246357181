import React from "react";
import useTranslation from "../hooks/useTranslation";

import "./RegistrationIncompleteNotification.scss";

const RegistrationIncompleteNotification = () => {
    const {t} = useTranslation();
    return (
        <div className="RegistrationIncompleteNotification-wrapper">
            <h3>{t('registrationIncomplete')}</h3>
        </div>
    );
};

export default RegistrationIncompleteNotification;
