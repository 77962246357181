import React, {useState, useEffect} from "react";
import Forms from "./form/Forms";
import {Config} from "../config/Config";
import Button from "./forms/Button";
import useTranslation from "../hooks/useTranslation";
import {axiosInstance} from "../axios";
import {useUserData} from "../context/UserContext";
import ErrorMessage from "./ErrorMessage";
import {onChange} from "../config/Tools";
import Icon from "@mdi/react";
import {mdiChevronDown, mdiChevronUp} from "@mdi/js";

import "./SignUpModal.scss";

const SignUpModal = ({closeModal}) => {
    const {signUp} = useUserData();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [agreeWithTerms, setAgreeWithTerms] = useState(false);
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [nationalityList, setNationalityList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const nationalityList = await axiosInstance.get('/api/nationality');
                const statusList = await axiosInstance.get("/api/status");
                setIsLoading(false);
                setNationalityList(nationalityList.data);
                setStatusList(statusList.data);
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, []);

    const validateInputs = ({email, password, passwordConfirmation, nationality, residenceStatus}) => {
        let error;
        if (password !== passwordConfirmation) {
            error = {errorCode: "passwordDoNotMatch", paramMap: {}};
        } else if (!Config.validationMap.email.test(email)) {
            error = {errorCode: "wrongEmailFormat"};
        } else if (password.length < 8 || passwordConfirmation.length < 8) {
            error = {errorCode: "invalidPasswordFormat"};
        } else if (nationalityList.find((nat) => nationality === nat.nationality).type.indexOf(6) !== -1) {
            error = {errorCode: "registrationOnlyForNonEU"};
        } else if (!statusList.find((status) => residenceStatus === status.type).acceptable) {
            error = {errorCode: "registrationOnlyForSomeVisaType"};
        }
        return error;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signUp(formData);
            closeModal();
        } catch (e) {
            setError(e);
        }
    }

    // const handleChange = (ref) => {
    //     const value = ref.getValue();
    //     const name = ref.getName();
    //
    //     if (name === "residenceStatus") {
    //         if (value) {
    //             const isError = !statusList.find((status) => value === status.type).acceptable;
    //             ref.setError(isError ? "registrationOnlyForSomeVisaType" : null);
    //         }
    //     } else if (name === "nationality") {
    //         const isError = nationalityList.find((nationality) => value === nationality.nationality).type.indexOf(6) !== -1;
    //         ref.setError(isError ? "registrationOnlyForNonEU" : null);
    //     } else if ((name === "password" || name === "passwordConfirmation") && value.length > 7) {
    //         const isError = !/^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#$%^&*])(?=.{8,})/.test(value);
    //         ref.setError(isError ? "invalidPasswordFormat" : null);
    //     }
    //     setData((prevState) => {
    //         return {...prevState, [name]: value};
    //     });
    // };
    // const toggleBool = (name) => {
    //     if (name === "isTermsOpen") {
    //         setIsTermsOpen(!isTermsOpen);
    //     } else if (name === "agreeWithTerms") {
    //         setAgreeWithTerms(!agreeWithTerms);
    //     }
    // };

    // const renderSignUpInputs = () => {
    //     const result = Object.entries(Config.userAttributes).map(([key, {
    //         type,
    //         options,
    //         errorMessage,
    //         infoMessage,
    //         prefix
    //     }]) => {
    //         if (key === "residenceStatus") {
    //             options = statusList.map((status) => ({[status.type]: t(status.type)}));
    //         } else if (key === "nationality") {
    //             options = nationalityList.map(({nationality}) => ({[nationality]: nationality}));
    //         } else if (key === "sex") {
    //             options = [{male: t("male")}, {female: t("female")}];
    //         }
    //         const props = {
    //             type: type,
    //             name: key,
    //             label: t(key),
    //             required: true,
    //             onChange: handleChange,
    //             defaultValue: data[key],
    //             ...options && {options, emptyOption: true},
    //             ...errorMessage && {errorMessage: t(errorMessage)},
    //             ...infoMessage && {infoMessage: t(infoMessage)},
    //             ...prefix && {prefix},
    //         };
    //         return <Input {...props} />;
    //     });
    //     result.push(
    //         <>
    //             <div className="termAndCondition">
    //                 <Input
    //                     name="agreeWithTerms"
    //                     label={t("agreeWithTerms")}
    //                     type="checkbox"
    //                     required
    //                     inline
    //                     colWidth="col-8"
    //                     defaultValue={agreeWithTerms}
    //                     onChange={() => toggleBool("agreeWithTerms")}
    //                 />
    //
    //             </div>
    //         </>
    //     );
    //     return React.Children.toArray(result);
    // };

    const handleStatusValidation = (status) => {
        return statusList.find(s => s.type === status).acceptable ? null : "registrationOnlyForSomeVisaType";
    }

    const handleNationalityValidation = (nationality) => {
        return nationalityList.find(n => n.nationality === nationality).type.indexOf(6) !== -1 ? "registrationOnlyForNonEU" : null;
    }

    const sexOptions = [{label: t('male'), value: 'male'}, {label: t('female'), value: 'female'}];
    const nationalityOptions = nationalityList.map(({nationality}) => ({label: t(nationality), value: nationality}));
    const statusOptions = statusList.map((status) => ({label: t(status.type), value: status.type}));
    const iconPath = isTermsOpen ? mdiChevronUp : mdiChevronDown;
    return (
        <form onSubmit={handleSubmit} className="SignUpModal">
            <div className='modal-body modalBody'>
                <div>
                    <Forms.Text name="name" regex={Config.validationMap.textRegex} validationError='onlyLatinCharacter'
                                description={t("exactNameSurname")} value={formData.name}
                                onChange={onChange(setFormData)} required/>

                    <Forms.Text name="surname" description={t("exactNameSurname")} value={formData.surname}
                                regex={Config.validationMap.textRegex} validationError='onlyLatinCharacter'
                                onChange={onChange(setFormData)} required/>

                    <Forms.Select name="sex" options={sexOptions} value={formData.sex} required
                                  onChange={onChange(setFormData)} label={t('sex')}/>

                    <Forms.Select name="nationality" options={nationalityOptions} value={formData.nationality}
                                  onChange={onChange(setFormData)} required onValidate={handleNationalityValidation}/>

                    <Forms.Select name="residenceStatus" options={statusOptions} value={formData.status}
                                  onChange={onChange(setFormData)} onValidate={handleStatusValidation} required/>

                    <Forms.Text name="mobile" regex={Config.validationMap.mobile} value={formData.mobile}
                                onChange={onChange(setFormData)} validationError="onlyCzechPhoneNumbers" required/>

                    <Forms.Text name="email" regex={Config.validationMap.email} validationError="wrongEmailFormat"
                                value={formData.email} required onChange={onChange(setFormData)}/>

                    <Forms.Text password name="password" regex={Config.validationMap.password}
                                validationError="invalidPasswordFormat" value={formData.password} required
                                onChange={onChange(setFormData)}/>

                    <Forms.Text password name="passwordConfirmation" label={t("passwordAgain")}
                                regex={Config.validationMap.password} validationError="invalidPasswordFormat"
                                value={formData.passwordConfirmation} required onChange={onChange(setFormData)}/>

                    <Forms.Date name="dateOfBirth" value={formData.dateOfBirth} required
                                onChange={onChange(setFormData)}/>

                    <Forms.Checkbox value={agreeWithTerms} name="termsAndConditions"
                                    label={t('agreeWithTerms')} required
                                    onChange={() => setAgreeWithTerms(!agreeWithTerms)}/>
                    <Icon size={1} alt="" path={iconPath} onClick={() => setIsTermsOpen(!isTermsOpen)}/>

                    <ErrorMessage error={error}/>
                </div>
                {isTermsOpen && (
                    <div>
                        {t("termAndCondition")}
                        <a target="_blank" rel="noopener noreferrer" href="https://icpraha.com/ochrana-osobnich-udaju/">
                            <label>&nbsp;ICPraha</label>
                        </a>
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <Button dark onClick={closeModal} label={t("close")}/>
                <Button type="submit" label={t("signup")}/>
            </div>
        </form>
    );
};

export default SignUpModal;