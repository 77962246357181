import React, {useState} from "react";
import Button from "../../bricks/forms/Button";
import {Tools} from "../../config/Tools";
import QRCode from "react-qr-code";
import {useUserData} from "../../context/UserContext";
import useTranslation from "../../hooks/useTranslation";
import {useAppData} from "../../context/AppDataContext";

import "./CourseOverview-Payment.scss";


const CourseOverviewPayment = () => {
    const {t, translations} = useTranslation();
    const {user, courseRegistration} = useUserData();
    const {data} = useAppData();
    const [paymentOption, setPaymentOption] = useState("bankTransfer");


    const _getPaymentOption = () => {
        switch (paymentOption) {
            case "bankTransfer":
                return (
                    <Button type="button" onClick={() => window.print()}
                            label={t("printPaymentInformation")}/>
                );
            case "depositOnAccount":
                return (
                    <div className={"wrapper-payment-info-wrapper-payment-buttons-options"}>
                        <Button type="button" onClick={() => window.open("https://www.fio.cz/o-nas/kontakty")}
                                label={t("bankBranch")}
                        />
                        <Button type="button" onClick={() => window.print()}
                                label={t("printPaymentInformation")}/>
                    </div>
                );
            case "qrPayment":
                const {bankAccount, coursePrice} = data;
                const dueDate = new Date();
                const month = dueDate.getMonth() + 1;
                const day = dueDate.getDate();
                // 1 = main; 2 = special
                const appNumber = process.env.REACT_APP_APP_TYPE === "main" ? 1 : 2;
                const specificSymbol = `${appNumber}${data.period.period.replace('/', '')}`;
                const qrValue = `SPD*1.0*ACC:${bankAccount}*AM:${coursePrice}*CC:CZK*X-VS:${user.varSymbol}*X-SS:${specificSymbol}*DT:${dueDate.getFullYear()}${month < 10 ? `0${month}` : month}${day < 10 ? `0${day}` : day}*MSG:motivacni zaloha ${courseRegistration.course.code}`;
                return (
                    <div>
                        <QRCode value={qrValue}/>
                    </div>
                );
            default:
                return null;
        }
    }

    const _getPaymentButton = () => {
        const options = ["bankTransfer", "depositOnAccount", "qrPayment"].map(option => {
                const props = {
                    onClick: () => setPaymentOption(option)
                };
                paymentOption === option && (props.className = "payment-info-wrapper-payment-buttons-selected-option");
                return (
                    <div {...props}><p>{t(option)}</p></div>
                );
            }
        );
        return (
            <div className={"payment-info-wrapper-payment-buttons"}>
                {React.Children.toArray(options)}
            </div>
        );
    }

    const _getPaymentInfo = () => {
        if (courseRegistration.isAlternate === false && courseRegistration.paymentCredited === false) {
            const dueDateValue = t("dueDateValue");
            return (
                <div className={"wrapper-payment-info"}>
                    <div className={"wrapper-payment-info-wrapper"}>
                        <p className={"payment-info-wrapper-payment"}>{t("payment")}</p>
                        <p>{t("motivationalAdvanceTerm").replace("%dueDate", dueDateValue)}</p>
                        {_getPaymentButton()}
                        <div className={"payment-info-block"}>
                            <label>{t("bankAccount")}</label>
                            <label>{t("bankAccountNumber")}</label>
                        </div>
                        <div className={"payment-info-block"}>
                            <label>{t("variableSymbol")}</label>
                            <label>{user.varSymbol}</label>
                        </div>
                        <div className={"payment-info-block"}>
                            <label>{t("dueDate")}</label>
                            <label>{dueDateValue}</label>
                        </div>
                        <div className={"payment-info-block"}>
                            <label>{t("amount")}</label>
                            <label>{t("amountValue")}</label>
                        </div>
                        {_getPaymentOption()}
                        <div className={"payment-warning-block"}>
                            <label>{t("paymentWarning")}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const _getPaymentDate = () => {
        if (courseRegistration.paymentCredited) {
            return courseRegistration.paymentCreditedId?.paymentData?.date && Tools.getLocalDateString(new Date(courseRegistration.paymentCreditedId.paymentData.date).toISOString());
        }
    }

    const _getPaymentStatus = () => {
        if (courseRegistration.isAlternate === false) {
            let status;
            if (courseRegistration.paymentRefunded === false) {
                status = (<div className="course-overview-wrapper-payment-status-wrapper">
                    <div className="payment-badge"
                         style={{backgroundColor: "red"}}>{t("notRefunded")}</div>
                    <div className="payment-badge-description">{t("paymentNotRefunded")}</div>
                </div>);
            } else if (courseRegistration.paymentRefunded) {
                status = (<div className="course-overview-wrapper-payment-status-wrapper">
                    <div className="payment-badge"
                         style={{backgroundColor: "green"}}>{t("refunded")}</div>
                    <div
                        className="payment-badge-description">{courseRegistration.refundInCash ? t("refundedInCash") : t("paymentRefunded")}</div>
                </div>);
            } else if (courseRegistration.paymentCredited) {
                status = (<div className="course-overview-wrapper-payment-status-wrapper">
                    <div className="payment-badge"
                         style={{backgroundColor: "green"}}>{t("paid")}</div>
                    <div
                        className="payment-badge-description">{t("paymentReceived")}{_getPaymentDate()}</div>
                </div>);
            } else {
                status = (<div className="course-overview-wrapper-payment-status-wrapper">
                    <div className="payment-badge"
                         style={{backgroundColor: "red"}}>{t("unpaid")}</div>
                    <div className="payment-badge-description">{t("paymentNotReceived")}</div>
                </div>);
            }

            return (<div className="course-overview-wrapper-payment-status">
                <h3>{t("paymentStatus")}</h3>
                {status}
            </div>);
        }
    }

    const _renderInfoListByMutationCode = (matcher) => {
        if (courseRegistration.isAlternate === false) {
            const tmpTranslations = [];
            Object.entries(translations).forEach(([key, trans]) => {
                const matches = key.match(matcher);
                if (matches) {
                    tmpTranslations.push({index: matches[1], translation: trans});
                }
            });
            const liItems = tmpTranslations
                .sort((a, b) => a.index - b.index)
                .map(item => (
                    <li key={item.index}>{item.translation}</li>
                ));
            return (
                <ul>{liItems}</ul>
            );
        }
    }

    return (
        <div className="CourseOverviewPayment">
            <div className="block">
                <h3>
                    {courseRegistration.isAlternate === false && t("registeredInCourse")}
                    {courseRegistration.isAlternate === true && t("registeredAsSubstitute")}
                    {(courseRegistration.isAlternate === null || courseRegistration.isAlternate === undefined) && t("notAssignedInCourse")}
                </h3>
                {_renderInfoListByMutationCode(/deposit_info_([0-9]+)/)}
                {_getPaymentInfo()}
                {_getPaymentStatus()}
            </div>
        </div>
    );
}

export default CourseOverviewPayment;
