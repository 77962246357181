import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {Config} from "../config/Config";

export const TranslationContext = createContext();

export const TranslationProvider = ({children}) => {
    const getBrowserLang = () => {
        const nav = window.navigator;
        const browserLang = nav.language || nav.userLanguage;
        return Config.browserLanguageMap[browserLang] || "us"; // languages is the mapping object mentioned in the previous example
    };

    const [translations, setTranslations] = useState({});
    const [data, setData] = useState({});
    const [language, setLanguage] = useState(getBrowserLang);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        const fetchTranslations = async () => {
            const response = await axios.get(`/public/translations.json?t=${new Date().getTime()}`);
            setData(response.data);
            setTranslations(response.data[language]);
            setLanguages(Object.keys(response.data));
        };
        fetchTranslations();
    }, []);

    useEffect(() => {
        setTranslations(data[language]);
    }, [language]);


    return (
        <TranslationContext.Provider value={{translations, language, setLanguage, languages}}>
            {children}
        </TranslationContext.Provider>
    );
};
