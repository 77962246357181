import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {TranslationProvider} from "./context/LanguageContext";
import {AppDataProvider} from "./context/AppDataContext";
import {UserProvider} from "./context/UserContext";
import {AxiosWrapper} from "./axios";

// react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

// react-accessible-accordion
import 'react-accessible-accordion/dist/fancy-example.css';

// react-toastify
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <TranslationProvider>
            <AppDataProvider>
                <UserProvider>
                    <AxiosWrapper/>
                    <App/>
                </UserProvider>
            </AppDataProvider>
        </TranslationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
