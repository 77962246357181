import React, {useState} from "react";
import ErrorMessage from "../../bricks/ErrorMessage";
import {axiosInstance} from "../../axios";
import useTranslation from "../../hooks/useTranslation";
import Forms from "../../bricks/form/Forms";
import {useNavigate, useParams} from "react-router-dom";
import {useAppData} from "../../context/AppDataContext";

import "./SetNewPassword.scss";

const SetNewPassword = () => {
    const {setSuccess} = useAppData();
    const {recoveryString} = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const {t} = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true)
            await axiosInstance.post(`/api/setNewPassword`, {passwordConfirmation, password, recoveryString});
            setSuccess(t('passwordChanged'));
            navigate('/');
        } catch (error) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="SetNewPassword">
            <div className="container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>{t("passwordReset")}</h2>
                    <Forms.Text
                        type="password"
                        value={password}
                        onChange={(_, val) => setPassword(val)}
                        required
                        disabled={isLoading}
                        label={t('setNewPassword')}
                    />
                    <Forms.Text
                        type="password"
                        disabled={isLoading}
                        label={t('setNewPasswordAgain')}
                        value={passwordConfirmation}
                        onChange={(_, val) => setPasswordConfirmation(val)}
                        required
                    />
                    <div className="controls">
                        <Forms.Button type="submit" label={t('restore')}/>
                    </div>
                </form>
                <ErrorMessage error={error}/>
            </div>
        </div>
    );
};

export default SetNewPassword;
