import React from "react";
import CourseIntroduction from "./CourseIntroduction";

const Homepage = () => {
    return (
        <div className="wrapper">
            <CourseIntroduction mutationKey={"register_phase"}/>
        </div>
    );
};

export default Homepage;