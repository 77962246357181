import React, {createContext, useContext, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import useFetch from "../hooks/useFetch";

const AppDataContext = createContext();

export const AppDataProvider = ({children}) => {
    const {data, isLoading, error: loadError} = useFetch("/api/loadAppData");
    const [error, setError] = useState();
    const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
    const [isTestVisible, setIsTestVisible] = useState(false);
    const [isCourseDraw, setIsCourseDraw] = useState();
    const [drawIsOver, setDrawIsOver] = useState();

    useEffect(() => {
        if (loadError) {
            setError(loadError);
        }
    }, [loadError]);

    const setErrorHandler = e => setError(e);

    useEffect(() => {
        if (data) {
            const now = new Date();
            setIsRegistrationOpen(now > new Date(data.period.registrationStartDate) && now < new Date(data.period.registrationEndDate));
            setIsTestVisible(now > new Date(data.period.sortingTestStartDate) && now < new Date(data.period.sortingTestEndDate));
            setIsCourseDraw(now > new Date(data.period.courseDrawDate) && now < new Date(data.period.showDrawResultDate));
            setDrawIsOver(now > new Date(data.period.showDrawResultDate));
        }
    }, [data]);

    const setInfo = message => toast.info(message);
    const setSuccess = message => toast.success(message);

    return (
        <AppDataContext.Provider value={{
            data,
            isLoading,
            error,
            isRegistrationOpen,
            isTestVisible,
            setError: setErrorHandler,
            isCourseDraw,
            drawIsOver,
            setSuccess,
            setInfo
        }}>
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = () => {
    return useContext(AppDataContext);
};
