import * as React from "react";
import {uniqueId} from "../../config/Tools";
import {Autocomplete as AC} from '@mui/material';
import TextField from '@mui/material/TextField';
import "./Autocomplete.css";
import {useState} from "react";

function Autocomplete(props) {
    const [value, setValue] = useState(props.value || '');
    const [inputValue, setInputValue] = useState(props.value || '');

    const disabled = props.disabled || false;
    const id = uniqueId();
    const idKey = `& FormAutcomplete_${id}`;
    const colWidth = props.colWidth || 50;
    let className = `col-${colWidth}`;
    props.className && (className = `${className} ${props.className}`);

    const onChange = (_, selectedVal) => {
        setValue(selectedVal);
        props.onChange?.(props.name, selectedVal);
    }

    return (
        <div className={className}>
            {props.label && <label htmlFor={id}>{props.label}</label>}
            <AC
                disablePortal
                id={idKey}
                options={props.options}
                value={value}
                // inputValue={inputValue}
                onChange={onChange}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                sx={{
                    // border: "1px solid blue",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    "& .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "0 1.5rem"
                    }
                }} renderInput={(params) => <TextField type="text" id={id}
                                                       {...params} placeholder={props.placeholder || ''}
                                                       disabled={disabled}/>
            }
            />
        </div>
    );
}

export default Autocomplete;