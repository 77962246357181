import './Checkbox.scss';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Checkboxes(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    return (
        <label className="FormCheckboxes">
            {props.label}
            <div className="choices">
                {props.options.map(option => (
                        <div>
                            <label>
                                {option.label}
                                <input checked={props.value.indexOf(option.value) !== -1}
                                       type="checkbox" {...getMainProps(inputMainProps)}/>
                            </label>
                        </div>
                    )
                )}
            </div>
        </label>
    );
}

export default Checkboxes;