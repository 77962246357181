import React, {useEffect, useRef, useState} from "react";
import useTranslation from "../hooks/useTranslation";

import "./LanguageSelector.scss";
import {languageOptions} from "../config/Config";

const LanguageSelector = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {t, language, setLanguage, languages} = useTranslation();
    const containerRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleDocumentClick);
        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, []);

    const handleOptionClick = (option) => {
        setLanguage(option);
        setIsOpen(false);
    };

    const handleDocumentClick = (event) => {
        if (!containerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className="LanguageSelector" ref={containerRef}>
            <div className="LanguageSelector__selected" onClick={() => setIsOpen(!isOpen)}>
                <span className={`flag-icon flag-icon-${language} wrapper-flag`}/>
                <span>{languageOptions[language]}</span>
                <div className="separator"/>
                <span className={`arrow ${isOpen ? "up" : "down"}`}/>
            </div>
            {isOpen && (
                <div className="LanguageSelector__options">
                    {languages.map(lang => (
                        <div
                            key={lang}
                            className="language-option"
                            onClick={() => handleOptionClick(lang)}
                        >
                            <span className={`flag-icon flag-icon-${lang} wrapper-flag`}/>
                            <span>{languageOptions[lang]}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
