import React from "react";
import Button from "../../bricks/forms/Button";
import {Tools} from "../../config/Tools";
import {useUserData} from "../../context/UserContext";
import useTranslation from "../../hooks/useTranslation";

import "./CourseOverview.scss";

const CourseOverviewOtherTests = ({course}) => {
    const {t, translations} = useTranslation();
    const {courseRegistration, testResultList, startOtherTest} = useUserData();


    const _renderInfoListByMutationCode = (matcher) => {
        if (courseRegistration.isAlternate === false) {
            const tmpTranslations = [];
            Object.entries(translations).forEach(([key, trans]) => {
                const matches = key.match(matcher);
                if (matches) {
                    tmpTranslations.push({index: matches[1], translation: trans});
                }
            });
            const liItems = tmpTranslations
                .sort((a, b) => a.index - b.index)
                .map(item => (
                    <li key={item.index}>{item.translation}</li>
                ));
            return (
                <ul>{liItems}</ul>
            );
        }
    }

    const handleStartOtherTest = (courseId, courseTestId, testToComplete) => {
        startOtherTest(courseId, courseTestId, testToComplete);
    }

    const _renderOtherTests = () => {
        if (course?.testConfig) {
            let courseOtherTests = course.testConfig;
            const testResultMap = testResultList.reduce((acc, testResult) => {
                acc[testResult.testId] = testResult;
                return acc;
            }, {});

            if (courseOtherTests) {
                courseOtherTests = courseOtherTests.map(courseTest => {
                    let completed = false;
                    let dateCondition = false;


                    // completed and testToComplete
                    if (testResultMap[courseTest.test]) {
                        if (testResultMap[courseTest.test].testToComplete) {
                            courseTest.testToComplete = testResultMap[courseTest.test].testToComplete;
                        } else {
                            completed = true;
                            courseTest.result = testResultMap[courseTest.test].result;
                            courseTest.resultPercentage = testResultMap[courseTest.test]?.resultPercentage;
                        }
                    }

                    // check if test can be started
                    let sDate = new Date(courseTest.startDate);
                    let eDate = new Date(courseTest.endDate);
                    const now = new Date();
                    if (now > sDate && now < eDate) {
                        dateCondition = true;
                    }
                    if (!dateCondition) {
                        const overwriteOtherTest = courseRegistration.overwriteOtherTestConfig.find(overwrite => overwrite.test === courseTest._id);
                        if (overwriteOtherTest) {
                            sDate = new Date(overwriteOtherTest.startDate);
                            eDate = new Date(overwriteOtherTest.endDate);
                            if (now > sDate && now < eDate) {
                                dateCondition = true;
                            }
                        }
                    }

                    // validate test date
                    courseTest.showStartButton = !completed && dateCondition;
                    courseTest.disabledButton = dateCondition && !completed;
                    return courseTest;
                });

                const renderedCourses = React.Children.toArray(courseOtherTests.map(courseTest => {
                    return (<div className="other-test-item">
                        <div className="course-other-tests-info">
                            <label>{t(courseTest.customName)}:&nbsp;</label>
                            <label>{Tools.getLocalDateTimeString(courseTest.startDate)} - {Tools.getLocalDateTimeString(courseTest.endDate)}</label>
                        </div>
                        {
                            courseTest.hasOwnProperty('result') && <div className="test-result">
                                <label className="result">{courseTest.result} {t('points')} ({courseTest.resultPercentage}%)</label>
                            </div>
                        }
                        <div>
                            {
                                courseTest.showStartButton &&
                                <Button
                                    onClick={() => handleStartOtherTest(courseTest.test, courseTest.testToComplete || 1)}
                                    label={t("startTest")}
                                />
                            }
                        </div>
                    </div>)
                }));

                return (<div className="course-overview-wrapper-tests-wrapper">
                    {renderedCourses}
                </div>);
            }
        }
    }
    return (
        <div className="CourseOverviewOtherTests">
            <div className="block">
                {_renderInfoListByMutationCode(/sortTest_info_([0-9]+)/)}
                {_renderOtherTests()}
            </div>
        </div>
    );
}

export default CourseOverviewOtherTests;
