import decode from 'jwt-decode';
import administrationLsi from "../assets/administration-lsi";
import {Tools} from "../config/Tools";

export default class AuthService {
    // Initializing important variables
    constructor() {
        this.serverApiUrl = process.env.REACT_APP_SERVER_API_URL; // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getDecodedToken = this.getDecodedToken.bind(this);
    }

    _loadAdministrationLsi() {
        administrationLsi.forEach(languageMutationItem => {
            Object.entries(languageMutationItem.values).forEach(([lang, mutation]) => {
                window.ICPraha.languageMutationMap[lang][languageMutationItem.code] = mutation;
            })
        });
    }

    startTest(courseId, testId, testToComplete = 1) {
        localStorage.setItem('courseTest', JSON.stringify({courseId, testId, testToComplete}));
        window.location.reload();
    }

    updateCourseTestTestToComplete(testToComplete) {
        const courseTest = this.getCourseTest();
        courseTest.testToComplete = testToComplete;
        localStorage.setItem('courseTest', JSON.stringify(courseTest));
    }

    isTestStarted() {
        return !!localStorage.getItem('courseTest');
    }

    stopCourseTest() {
        return localStorage.removeItem('courseTest');
    }

    getCourseTest() {
        return JSON.parse(localStorage.getItem('courseTest'));
    }

    async login(email, password) {
        // Get a token from api server using the fetch api
        try {
            const token = await this.fetch('api/public/signin', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    password
                })
            });
            await this.setToken(token);
            window.ICPraha.history.push(this.getCorrectRoute());
        } catch (e) {
            throw e;
        }
    }

    getCourseRegistration() {
        return window.ICPraha.courseRegistration;
    }

    async signup(body) {
        // Get a token from api server using the fetch api
        try {
            const token = await this.fetch('api/public/signup', {
                method: 'POST',
                body: JSON.stringify(body)
            });
            await this.setToken(token);
            window.ICPraha.history.push(this.getCorrectRoute());
        } catch (e) {
            throw e;
        }
    }

    async updateAppConfig(appConfig) {
        try {
            const updatedAppConfig = await this.fetch('api/private/appConfig', {
                method: 'POST',
                body: JSON.stringify(appConfig)
            });
            window.ICPraha.appConfig = {
                actualPeriod: updatedAppConfig.actualPeriod
            };
            if (window.ICPraha.period.period !== appConfig.actualPeriod) {
                window.ICPraha.period = await this.fetch("api/private/period");
            }
        } catch (e) {
            throw e;
        }
    }

    async loadAppConfig() {
        try {
            return await this.fetch('api/private/appConfig');
        } catch (err) {
            throw err;
        }
    }

    async loadPeriod(period = null) {
        if (!period) period = window.ICPraha.period.period;
        try {
            return await this.fetch(`api/private/period/${period}`);
        } catch (err) {
            throw err;
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isAdmin() {
        // Checks if user is cast in submitted profile
        if (this.loggedIn()) {
            return this.getDecodedToken().right === 2
        }
        return false;
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            const expired = decoded.exp < Date.now() / 1000;
            if (expired) {
                localStorage.removeItem('courseTest');
            }
            return expired; // Checking if token is expired. N
        } catch (err) {
            return false;
        }
    }

    async setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken);
        await this.setTokenData();
    }

    async setTokenData() {
        if (this.loggedIn()) {
            const user = this.getDecodedToken();
            const userData = await this.fetch(`api/private/user/${user._id}`);
            window.ICPraha.userData = {
                course: userData.course,
                courseRegistration: userData.courseRegistration,
                testResultList: userData.testResultList,
                testIntroductionStartTime: userData.testIntroductionStartTime
            };
            if (user.right === 2) {
                this._loadAdministrationLsi();
            }
        } else {
            delete window.ICPraha.userData;
        }
    }

    async getUserTestIntroductionStartTime() {
        if (this.loggedIn()) {
            const testIntroductionStartTime = window.ICPraha.userData?.testIntroductionStartTime;
            if (!testIntroductionStartTime) {
                const user = await this.fetch('api/private/user/startTestIntroduction', {
                    method: 'POST',
                    body: JSON.stringify({date: new Date()})
                });
                window.ICPraha.userData.testIntroductionStartTime = user.testIntroductionStartTime;
            }
            return window.ICPraha.userData.testIntroductionStartTime;
        }
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token');
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
        localStorage.removeItem('courseTest');
        delete window.ICPraha.userData;
        window.ICPraha.history.push('/');
        window.ICPraha.AppForceReload();
    }

    getDecodedToken() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    _checkStatus(response, isFile) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            if (isFile) {
                return response.blob().then(blob => {
                    return blob;
                });
            } else {
                const isJson = response.headers.get('content-type')?.indexOf('application/json') !== -1;
                if (isJson) {
                    return response.json().then(json => {
                        return json;
                    });
                } else {
                    return response.text().then(text => {
                        return text;
                    })
                }
            }
        } else {
            return Promise.resolve(response.json()).then(err => {
                throw err;
            });
        }
    }

    async fetch(url, options = {method: "GET"}) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        let isFile;
        if (options && options.type) {
            isFile = options.type === "file";
            delete options.type;
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
        }

        url = `${this.serverApiUrl}/${url}`;
        try {
            return await new Promise((resolve => {
                fetch(url, {
                    headers,
                    ...options
                }).then(response => {
                    resolve(this._checkStatus(response, isFile));
                }).catch(err => {
                    resolve(err);
                });
            }));
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    getAvailableOtherTest(testResultMap) {
        if (window.ICPraha.userData?.courseRegistration && window.ICPraha.userData?.course?.testConfig) {
            let testToComplete;
            const {testConfig, _id: courseId} = window.ICPraha.userData.course;
            // get first available test
            const availableOtherTest = testConfig.find((testConfig) => {
                testToComplete = 1;
                // 1. Check if test is already completed
                const testResultKey = `${courseId}-${testConfig._id}`;
                if (testResultMap[testResultKey]) {
                    if (testResultMap[testResultKey].testToComplete) {
                        testToComplete = testResultMap[testResultKey].testToComplete;
                    } else {
                        return false;
                    }
                }

                // 2. Compare dates
                const sDate = new Date(testConfig.startDate);
                const eDate = new Date(testConfig.endDate);
                const now = new Date();
                if (!(now > sDate && now < eDate)) {
                    return false;
                }

                // 3. Check if user have access to test
                const isAlternateUserState = window.ICPraha.userData.courseRegistration.isAlternate;
                const availableFor = testConfig.availableFor;
                // isAlternate = true
                if (isAlternateUserState === true && availableFor.isAlternate !== true) {
                    return false;
                } else if (isAlternateUserState === false && availableFor.notAlternate !== true) {
                    return false;
                } else if ((isAlternateUserState === undefined || isAlternateUserState === null) && availableFor.rest !== true) {
                    return false;
                }

                return true;
            });
            if (availableOtherTest) {
                return {
                    testPeriod: availableOtherTest._id,
                    testToComplete,
                    courseId: window.ICPraha.userData.course._id
                };
            }
        }
    }

    getTestResultMap() {
        return window.ICPraha.userData?.testResultList?.reduce((acc, testResult) => {
            acc[testResult.testType || `${testResult.courseId}-${testResult.testPeriod}`] = testResult;
            return acc;
        }, {});
    }

    getCorrectRoute() {
        let route;
        if (this.loggedIn()) {
            // handle admin route
            if (this.isAdmin()) {
                return false;
            } else {
                if (this.isTestStarted()) {
                    const courseTest = this.getCourseTest();
                    return `/test/${courseTest.courseId}/${courseTest.testId}/${courseTest.testToComplete}`;
                }

                const testResultMap = this.getTestResultMap();

                // 1. If user doesnt saw test introduction, redirect to test introduction
                if (!this.sawTestIntroduction()) {
                    return '/testIntroduction';
                }

                // 2. If user have "otherTest" to complete - let him complete the test event if sortingTest is not filled
                // const otherTestData = this.getAvailableOtherTest(testResultMap);
                // if (otherTestData) {
                //     return `/test/${otherTestData.courseId}/${otherTestData.testPeriod}/${otherTestData.testToComplete}`;
                // }

                // 3. If the user has not filled in the entry test, redirect to the entry test
                if (!testResultMap.sortingTest) {
                    return '/test/sortingTest/1';
                }

                // 4. If the user has not completed the entry test, redirect to the entry test
                if (testResultMap.sortingTest.testToComplete) {
                    return `/test/sortingTest/${testResultMap.sortingTest.testToComplete}`;
                }

                // 5. If course is informed -> show course info
                if (window.ICPraha.userData?.course?.informed) {
                    return '/courseOverview';
                }

                // 6. If the draw of the courses is completed - show courseOverview
                if (new Date() > new Date(window.ICPraha.period.showDrawResultDate)) {
                    //  and user have course registration -> courseOverview
                    if (window.ICPraha.userData?.courseRegistration) {
                        return '/courseOverview';
                    }
                }

                // 5. If user has filled up entry test and no test to complete left
                if (testResultMap.sortingTest && !testResultMap.sortingTest.testToComplete) {
                    return '/courseSelection';
                }

                if (window.ICPraha.userData?.course) {
                    if (window.ICPraha.userData?.course?.informed) {
                        return '/courseOverview';
                    } else {
                        return '/courseSelection';
                    }
                }
            }
        } else {
            if (window.location.href.indexOf("recoverPassword") !== -1) {
                route = window.location.href.split("/");
                return `/recoverPassword/${route[route.length - 1]}`;
            } else {
                return '/';
            }
        }
    }

    sawTestIntroduction() {
        let saw = false;
        if (window.ICPraha.userData?.testIntroductionStartTime) {
            const startTime = new Date(window.ICPraha.userData.testIntroductionStartTime).getTime();
            const now = new Date().getTime();
            const testDuration = parseInt(process.env.REACT_APP_TEST_INTRODUCTION_TIME) * 1000;
            saw = now > (startTime + testDuration);
        }
        return saw;
    }

    async loadAppData() {
        const {languageMutationMap, period, userCount, qrInfo} = await this.fetch("api/public/load");
        window.ICPraha = {
            qrInfo,
            languageMutationMap,
            period,
            userCount,
            refs: {},
            lang: localStorage.lang || Tools.getLanguageByNavigator()
        };
    }

    updateTestResult(result) {
        const testResultList = window.ICPraha.userData?.testResultList;
        if (testResultList) {
            const existingTestResultIndex = testResultList.findIndex(testResult => testResult._id === result._id);
            if (existingTestResultIndex === -1) {
                window.ICPraha.userData.testResultList.push(result);
            } else {
                window.ICPraha.userData.testResultList[existingTestResultIndex] = result;
            }
        } else {
            window.ICPraha.userData.testResultList = [result];
        }
    }

}
