import React from "react";
import Forms from "../../bricks/form/Forms";

const TestQuestion = ({className, testQuestion, question, onChange, value}) => (
    <div className={className}>
        {testQuestion.instructions && <label className="instructions">{testQuestion.instructions}</label>}
        <h3>{question}</h3>
        <div className="answer-container">
            <Forms.Radio
                name={testQuestion._id}
                onChange={onChange}
                value={value}
                options={testQuestion.answerList.map(answer => ({label: answer.value, value: answer._id}))}
            />
        </div>
    </div>
);

export default TestQuestion;