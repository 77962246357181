import {useState, useEffect, useRef} from "react";
import axios from "axios";

const useFetch = (
    url,
    method = "GET",
    body = null,
    options = {},
    file = null
) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);

    const bodyRef = useRef(body);
    const optionsRef = useRef(options);
    const fileRef = useRef(file);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Nastavení pro odesílání souborů
                if (fileRef.current) {
                    optionsRef.current.headers = {
                        ...optionsRef.current.headers,
                        "Content-Type": "multipart/form-data",
                    };
                    bodyRef.current = new FormData();
                    bodyRef.current.append("file", fileRef.current);
                }

                const config = {
                    ...optionsRef.current,
                    withCredentials: true,
                    method,
                    url,
                };

                // Přidání těla požadavku, pokud je metoda POST, PUT nebo PATCH
                if (bodyRef.current && (method === "POST" || method === "PUT" || method === "PATCH")) {
                    config.data = bodyRef.current;
                }

                // Přidání konfigurace onUploadProgress, pokud je soubor k nahrání
                if (fileRef.current) {
                    config.onUploadProgress = (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    };
                }

                const response = await axios(config);
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [url, method]);

    return {data, isLoading, error, progress};
};

export default useFetch;
