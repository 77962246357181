import * as React from "react";
import './Date.scss';
import moment from "moment";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'min', 'max'];

function DateTime(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === 'value') {
                value = moment(value).format('YYYY-MM-DD');
            } else if (propName === 'min' || propName === 'max') {
                value = moment(value).format('YYYY-MM-DD');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    return (
        <label className="col-50">
            <label>{props.label}</label>
            <input type="datetime-local" {...getMainProps(inputMainProps)} disabled={disabled}/>
        </label>
    );
}

export default DateTime;