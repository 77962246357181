import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import "./CountDown.scss";

const CountDown = ({end, action}) => {

    const calculateTimeLeft = () => {
        let difference = +new Date(end) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
            action?.();
        }

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    return (
        <div className="CountDown-wrapper">
            <div>
                <p>{timeLeft.days || '0'}</p>
                <p>days</p>
            </div>
            <div>
                <p>{timeLeft.hours || '0'}</p>
                <p>hours</p>
            </div>
            <div>
                <p>{timeLeft.minutes || '0'}</p>
                <p>minutes</p>
            </div>
            <div>
                <p>{timeLeft.seconds || '0'}</p>
                <p>seconds</p>
            </div>
        </div>
    );
};

CountDown.propTypes = {
    end: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
        PropTypes.number
    ]).isRequired, action: PropTypes.func
};

export default CountDown;
