import React, {useState} from "react";
import Button from "./forms/Button";
import Forms from "./form/Forms";
import useTranslation from "../hooks/useTranslation";
import {useUserData} from "../context/UserContext";
import ErrorMessage from "./ErrorMessage";
import {axiosInstance} from "../axios";
import {useAppData} from "../context/AppDataContext";

import "./SignInModal.scss";

const SignInModalContent = ({handleChange, handleKeyPress, handleForgottenPassword, t, email, password}) => {
    return (
        <React.Fragment>
            <Forms.Text name="email" required onChange={handleChange} value={email}/>
            <Forms.Text password name="password" value={password} required onChange={handleChange}
                        onKeyPress={handleKeyPress}/>
            <p onClick={() => handleForgottenPassword(true)}>
                {t("forgottenPassword")}
            </p>
        </React.Fragment>
    );
};

const RecoverPasswordModalContent = ({handleChange, handleForgottenPassword, t, email}) => (
    <React.Fragment>
        <Forms.Text name="email" label={t("emailOfForgottenAccount")} required onChange={handleChange}
                    value={email}/>
        <p onClick={() => handleForgottenPassword(false)}>
            {t("signin")}
        </p>
    </React.Fragment>
);

const SignInModal = ({closeModal}) => {
    const {signIn} = useUserData();
    const {setSuccess} = useAppData();
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const {t} = useTranslation();

    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            handleSubmit(event);
        }
    }

    const handleChange = (name, value) => {
        setFormData({...formData, [name]: value});
    }

    const content = showResetPassword ? (
        <RecoverPasswordModalContent
            email={formData.email}
            handleChange={handleChange}
            handleForgottenPassword={val => setShowResetPassword(val)}
            t={t}
        />
    ) : (
        <SignInModalContent
            email={formData.email}
            password={formData.password}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            handleForgottenPassword={val => setShowResetPassword(val)}
            t={t}
        />
    );

    const buttonLabel = t(showResetPassword ? "send" : "signin");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError(null);
            // call reset password
            if (showResetPassword) {
                await axiosInstance.post('/api/resetPassword', {email: formData.email});
                setSuccess(t('emailWithPasswordRestoreSent'));
            } else {
                await signIn(formData.email, formData.password);
            }
            closeModal();
        } catch (e) {
            setError(e);
        }
    }

    return (
        <form className="SignInModal" onSubmit={handleSubmit}>
            <div className={`modal-body`}>
                {content}
                <ErrorMessage error={error}/>
            </div>
            <div className="modal-footer">
                <Button dark onClick={closeModal} label={t("close")}/>
                <Button type="submit" label={buttonLabel}/>
            </div>
        </form>
    );
};

export default SignInModal;