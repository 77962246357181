import React, {useEffect, useState} from "react";
import Course from "../bricks/forms/Course";
import CountDown from "./CountDown";
import AppInfo from "../bricks/AppInfo";
import {useAppData} from "../context/AppDataContext";
import Loading from "../bricks/Loading";

import {useUserData} from "../context/UserContext";
import {axiosInstance} from "../axios";
import ErrorMessage from "../bricks/ErrorMessage";

import "./CourseSelection.scss";

const CourseSelection = () => {
    const {data} = useAppData();
    const {user} = useUserData();
    const [courseList, setCourseList] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [courseRegLoading, setCourseRegLoading] = useState();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const {data} = await axiosInstance.get(`/api/course/listByUser`);
                setCourseList(data);
            } catch (error) {
                setError(error)
            } finally {
                setIsLoading(false);
            }
        };
        user && fetchUserData();
    }, [user]);

    const _renderCourseList = () => {
        if (courseList) {
            return <div className="CourseSelection list">
                {React.Children.toArray(courseList.map(course => {
                        return (
                            <Course course={course} setDisabled={isLoading => setCourseRegLoading(isLoading)}/>
                        );
                    }
                ))}
            </div>;
        }
    }

    const _getUpdatedTotalInCourseList = (courseId, action) => {
        const courseList = data.period.courseList;
        const index = courseList.findIndex(course => course._id === courseId);
        if (action === 'add') {
            courseList[index].total++;
        } else if (action === 'sub') {
            courseList[index].total--;
        }
        return courseList;
    }

    const _getAppStep = () => {
        const now = new Date();
        return (now > data.period.courseDrawDate && data.period.showDrawResultDate > now) ? 4 : 3;
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (error) {
            return <ErrorMessage error={error}/>;
        } else {
            return _renderCourseList();
        }
    }

    return (
        <div className="CourseSelection-wrapper">
            <AppInfo step={_getAppStep()}/>
            {renderContent()}
        </div>
    );
};

export default CourseSelection;
