// TestAudioQuestion.js
import React from "react";
import Forms from "../../bricks/form/Forms";

const TestAudioQuestion = ({className, question, testQuestion, onChange, value}) => (
    <div className={className}>
        <label className="instructions">{testQuestion.instructions}</label>
        <h3>{question}</h3>
        {
            testQuestion.record ? <audio controls>
                <source src={testQuestion.record.dest + '/' + testQuestion.record.filename} type="audio/mpeg"/>
                Váš prohlížeč nepodporuje audio tag.
            </audio> : null
        }
        <div className="answer-container">
            <Forms.Radio
                name={testQuestion._id}
                onChange={onChange}
                value={value}
                options={testQuestion.answerList.map(answer => ({label: answer.value, value: answer._id}))}
            />
        </div>
    </div>
);

export default TestAudioQuestion;
