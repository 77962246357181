import {useContext} from "react";
import {TranslationContext} from "../context/LanguageContext";

const useTranslation = () => {
    const {translations, language, setLanguage, languages} = useContext(TranslationContext);

    const t = (key) => translations?.[key] || key;

    return {t, setLanguage, language, languages, translations};
};

export default useTranslation;
