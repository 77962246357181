import React, {useState} from "react";
import AuthService from "../../bricks/AuthService";
import Forms from "../../bricks/form/Forms";
import FileSaver from "file-saver";
import {useUserData} from "../../context/UserContext";
import useTranslation from "../../hooks/useTranslation";

import "./CourseOverview-Info.scss";
import {axiosInstance} from "../../axios";

const authService = new AuthService();

const CourseOverviewInfo = () => {
    const {t, translations} = useTranslation();
    const {courseRegistration} = useUserData();
    const [error, setError] = useState();

    const _renderInfoListByMutationCode = (matcher) => {
        if (courseRegistration.isAlternate === false) {
            const tmpTranslations = [];
            Object.entries(translations).forEach(([key, trans]) => {
                const matches = key.match(matcher);
                if (matches) {
                    tmpTranslations.push({index: matches[1], translation: trans});
                }
            });
            const liItems = tmpTranslations
                .sort((a, b) => a.index - b.index)
                .map(item => (
                    <li key={item.index}>{item.translation}</li>
                ));
            return (
                <ul>{liItems}</ul>
            );
        }
    }

    const downloadCertificate = async (period) => {
        try {
            const {data} = await axiosInstance.get(`/api/certificate/generateUserCertificate/${period}`, {responseType: "blob"});
            FileSaver.saveAs(data, "certificate.pdf");
        } catch (e) {
            console.log(e);
            setError(e);
        }
    }

    const _renderCourseEnd = () => {
        if (courseRegistration.isAlternate === false && courseRegistration) {
            let content;
            if (courseRegistration.passed) {
                content = <div className="block course-passed">
                    {t("passedCourse")}<Forms.Button className="certificate-button"
                                                     label="PDF"
                                                     onClick={() => downloadCertificate(courseRegistration.period)}/>
                </div>;
            } else if (courseRegistration.passed === false) {
                content = <div className="block course-failed">
                    {t("failedCourse")}
                </div>;
            }
            return content;
        }
    }

    return (
        <div className="CourseOverviewInfo">
            <div className="block">
                <h3>
                    {courseRegistration.isAlternate === false && t("registeredInCourse")}
                    {courseRegistration.isAlternate === true && t("registeredAsSubstitute")}
                    {(courseRegistration.isAlternate === null || courseRegistration.isAlternate === undefined) && t("notAssignedInCourse")}
                </h3>
                {_renderInfoListByMutationCode(/yourCourse_info_([0-9]+)/)}
            </div>
            {_renderCourseEnd()}
        </div>
    );
}

export default CourseOverviewInfo;
