import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import LanguageSelector from "../bricks/LanguageSelector";
import SignInModal from "../bricks/SignInModal";
import SignUpModal from "../bricks/SignUpModal";
import headerPhoto from "../assets/header-photo-200.png";
import useTranslation from "../hooks/useTranslation";
import Modal from "../bricks/Modal";
import Forms from "../bricks/form/Forms";
import {useAppData} from "../context/AppDataContext";
import {useUserData} from "../context/UserContext";

import "./Header.scss";

const Header = () => {
    const {isLoading, isRegistrationOpen} = useAppData();
    const {user, signOut} = useUserData();
    const {t} = useTranslation();
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const navigate = useNavigate();

    const handleSignOut = () => {
        signOut(() => navigate("/"));
    }

    return isLoading ? "..." : (
        <header className="Header">
            <Modal show={showSignUpModal}
                   customTitle={<div><h2>{t('signup')}</h2><label>{t('inRomanCharacters')}</label></div>}
                   onClose={() => setShowSignUpModal(false)}>
                <SignUpModal/>
            </Modal>
            <Modal show={showSignInModal} title={t('login')} onClose={() => setShowSignInModal(false)}>
                <SignInModal/>
            </Modal>
            {process.env.REACT_APP_ENV === "test" && <h2>TESTOVACÍ PROSTŘEDÍ</h2>}
            <div className="shadow">
                <div className="Header__wrapper">
                    <div className="Header__logo">
                        <Link rel="canonical" to={"/"}>
                            <img className="photo" src={headerPhoto} alt="icp logo"/>
                        </Link>
                    </div>
                    <div>
                        <div className="controls">
                            {
                                user
                                    ? <>
                                        <Forms.Button label={t("signout")} onClick={handleSignOut}/>
                                        <Link className="Button button main" to="/profile">{t("courseHistory")}</Link>
                                    </>
                                    : <Forms.Button label={t("signin")} onClick={() => setShowSignInModal(true)}/>
                            }
                            {
                                isRegistrationOpen && !user &&
                                <Forms.Button label={t("signup")} onClick={() => setShowSignUpModal(true)}/>
                            }
                            <LanguageSelector/>
                        </div>
                        {user && (
                            <div className="loggedInUser">
                                {t("user")}: {user.email} ({user.varSymbol})
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="metropolisForAll">
                <h1>{t("metropolisForAll")}</h1>
            </div>
        </header>
    );
};

export default Header;