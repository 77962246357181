import React from 'react';
import './ErrorMessage.scss';
import useTranslation from "../hooks/useTranslation";

const ErrorMessage = ({error: e}) => {
    const {t} = useTranslation();

    if (!e) return;

    let error = e.message;
    if (e?.response?.data?.errorCode) {
        if (e.response.data.errorCode === "signup.validationFailed" && e.response.data?.error?.invalidFields) {
            error = t("signup.validationFailed").replace("%s", e.response.data.error.invalidFields.map(field => t(field)).join(", "));
        } else {
            error = t(e.response.data.errorCode);
        }
    }

    return (
        <div className="error-message">
            {error}
        </div>
    );
};

export default ErrorMessage;