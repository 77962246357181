import React from "react";
import PropTypes from "prop-types";
import useTranslation from "../hooks/useTranslation";

import "./Container.scss";

function CourseIntroduction(props) {
    const {t, language, translations} = useTranslation();

    // Funkce, která extrahuje poslední část z klíče pro třídění
    const getLastNumericValueFromKey = (key) => {
        const match = key.match(/\.(\d+)$/);
        if (match) {
            return parseInt(match[1], 10);
        }
        return 0; // pokud není match, vrátíme základní hodnotu
    };

    const getKeyVariants = (lang, mutationsKey) => {
        let variants = [];
        Object.keys(translations).forEach(key => {
            key = key.split(".")[0];
            if (key && key.match(`${mutationsKey}_`) && variants.indexOf(key) === -1) {
                variants.push(key);
            }
        });
        // Seřadíme klíče podle numerických hodnot
        variants.sort((a, b) => {
            const aValue = getLastNumericValueFromKey(a);
            const bValue = getLastNumericValueFromKey(b);
            return aValue - bValue;
        });
        return variants;
    };

    const keyVariants = getKeyVariants(language, props.mutationKey);

    const filteredKeys = (lang, filter) => {
        let key, keys = {};
        for (key in translations) {
            if (translations.hasOwnProperty(key) && key.match(filter))
                keys[key] = translations[key];
        }
        return keys;
    };

    const renderRows = (headerKey) => {
        const langKeys = filteredKeys(language, `${headerKey}..*`);

        // Seřadíme klíče podle poslední číselné hodnoty
        const sortedKeys = Object.keys(langKeys).sort((a, b) => {
            const aValue = getLastNumericValueFromKey(a);
            const bValue = getLastNumericValueFromKey(b);
            return aValue - bValue;
        });

        const result = sortedKeys.map((key) => (
            <p key={key} dangerouslySetInnerHTML={{__html: langKeys[key]}}/>
        ));

        return React.Children.toArray(result);
    };

    function renderContents() {
        if (language && translations) {
            const result = keyVariants.map((key) => {
                return (
                    <div key={key}>
                        <h2>{t(key)}</h2>
                        {renderRows(key)}
                    </div>
                );
            });
            return React.Children.toArray(result);
        }
    }

    return <div className="CourseIntroduction">{renderContents()}</div>;
}

CourseIntroduction.propTypes = {
    mutationKey: PropTypes.string,
};

export default CourseIntroduction;
