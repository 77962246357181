import React from "react";
import CountDown from "./CountDown";
import {useAppData} from "../context/AppDataContext";
import AppInfo from "../bricks/AppInfo";
import Loading from "../bricks/Loading";

import "./CourseDraw.scss";

function CourseDraw() {
    const {data} = useAppData();


    const _renderContent = () => {
        if (!data) {
            return <Loading/>;
        } else {
            return (
                <div className="container">
                    <CountDown end={new Date(data.period.showDrawResultDate).toISOString()}
                               action={() => alert("countdown alert")}/>
                </div>
            );
        }
    }
    return (
        <div className="CourseDraw">
            <AppInfo step={4}/>
            {_renderContent()}
        </div>
    );
}

export default CourseDraw;
