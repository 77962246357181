import Header from "./Header";

import "./Layout.scss";
// import Footer from "./Footer";

const Layout = ({children}) => {
    return (
        <div className="layout-container">
            <Header/>
            <main className="content">{children}</main>
            {/*<footer className="footer">Footer content goes here</footer>*/}
        </div>
    );
};

export default Layout;